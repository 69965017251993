
















































































import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import ContactForm from '../models/contactForm';

@Component
export default class Home extends Vue {
  get user(): User | null {
    return this.$store.getters['auth/getUser'];
  }

  data() {
    return {
      form: {
        email: '',
        firstName: '',
        lastName: '',
        organization: '',
        comment: '',
        sent: false,
      } as ContactForm,
      contactFeedback: {
        success: '',
        msg: '',
      },
    };
  }
}
