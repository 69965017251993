import { Module } from 'vuex';
import { RootState } from '@/store';
import { BuildLeaderboardDto, EligibleParticipant, EpUploadDto, UploadHistory } from '@/models';
import EPService from '@/api/eligibleParticipant';
import { buildLeaderboard } from '@/firebase/functions';

export type EligibleParticipantsState = {
  epGroups?: EpUploadDto[];
};

export const eligibleParticipants: Module<EligibleParticipantsState, RootState> = {
  namespaced: true,
  state: {},

  getters: {
    /**
     * Gets the details of an
     * @param eligibleParticipantId T
     * @returns EligibleParticipant | null
     */
    eligibleParticipants(
      state: EligibleParticipantsState
    ): (organizationId: string, seriesId: string) => EligibleParticipant[] | null {
      return (organizationId: string, seriesId: string) => {
        const epGroup = state.epGroups?.find(
          ep => ep.organizationId === organizationId && ep.seriesId === seriesId
        );
        if (epGroup === undefined) {
          return null;
        }
        return epGroup.eligibleParticipants;
      };
    },
  },

  actions: {
    /**
     * Gets a set of of EpS from for a given organization and season
     * @param epGroup is an object that contains the org id and season id of the EPs
     */
    async getEligibleParticipantsList(
      { commit, rootGetters },
      epGroup: { organizationId: string; seriesId: string }
    ) {
      const org = rootGetters['organizations/organizationDetails'](epGroup.organizationId);
      // If of is not null or not undefined then we know the current signed in user has access to these EPs
      if (org) {
        const eligibleParticipants = await EPService.getEligibleParticipants(
          epGroup.organizationId,
          epGroup.seriesId
        );
        commit('SET_ELIGIBLE_PARTICIPANTS', [{ eligibleParticipants, ...epGroup }]);
      } else {
        commit('SET_ELIGIBLE_PARTICIPANTS', []);
      }
    },

    async uploadEligibleParticipants({ dispatch, rootGetters }, epGroup: EpUploadDto) {
      try {
        const org = rootGetters['organizations/organizationDetails'](epGroup.organizationId);
        if (org) {
          await EPService.uploadEligibleParticipants(
            epGroup.organizationId,
            epGroup.seriesId,
            epGroup.eligibleParticipants
          );

          await dispatch('getEligibleParticipantsList', {
            organizationId: epGroup.organizationId,
            seriesId: epGroup.seriesId,
          });
          await buildLeaderboard(new BuildLeaderboardDto(org.id, epGroup.seriesId));
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error upload eligible participants',
            message: 'failed to upload eligible participants: ' + error,
          },
          { root: true }
        );
      }
    },

    async addEligibleParticipant(
      { dispatch, rootGetters },
      payload: {
        organizationId: string;
        seriesId: string;
        eligibleParticipant: EligibleParticipant;
      }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.organizationId);
        if (org) {
          await EPService.addEligibleParticipant(
            payload.organizationId,
            payload.seriesId,
            payload.eligibleParticipant
          );
          await dispatch('getEligibleParticipantsList', payload.organizationId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error add eligible participant',
            message: 'failed to add eligible participant: ' + error,
          },
          { root: true }
        );
      }
    },

    async updateEligibleParticipant(
      { dispatch, rootGetters },
      payload: {
        organizationId: string;
        seriesId: string;
        eligibleParticipant: EligibleParticipant;
      }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.organizationId);
        if (org) {
          await EPService.updateEligibleParticipant(
            payload.organizationId,
            payload.seriesId,
            payload.eligibleParticipant
          );
          await dispatch('getEligibleParticipantsList', payload.organizationId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update eligible participant',
            message: 'failed to update eligible participant: ' + error,
          },
          { root: true }
        );
      }
    },

    async deleteEligibleParticipant(
      { dispatch, rootGetters },
      payload: {
        organizationId: string;
        seriesId: string;
        eligibleParticipant: EligibleParticipant;
      }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.organizationId);
        if (org) {
          await EPService.deleteEligibleParticipant(
            payload.organizationId,
            payload.seriesId,
            payload.eligibleParticipant
          );
          await dispatch('getEligibleParticipantsList', payload.organizationId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error delete eligible participant',
            message: 'failed to delete eligible participant: ' + error,
          },
          { root: true }
        );
      }
    },

    async updateUploadHistory(
      { rootGetters, dispatch },
      payload: {
        organizationId: string;
        seriesId: string;
        uploadHistory: UploadHistory;
      }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.organizationId);
        if (org) {
          await EPService.updateEpUploadHistory(
            payload.organizationId,
            payload.seriesId,
            payload.uploadHistory
          );
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        console.error(error);
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update upload history',
            message: 'failed to update upload history: ' + error,
          },
          { root: true }
        );
      }
    },
  },
  mutations: {
    SET_ELIGIBLE_PARTICIPANTS(state, epGroups: EpUploadDto[]) {
      state.epGroups = epGroups;
    },
  },
};
