import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const auth = app.auth();
const perf = app.performance();
const storage = app.storage();

if (process.env.NODE_ENV === 'development') {
  const firestoreSettings: any = {};

  if ((window as any).Cypress) {
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    firestoreSettings.experimentalForceLongPolling = true;
  }

  auth.useEmulator('http://localhost:9099/');
  app.functions().useEmulator('localhost', 5001);
  // db.useEmulator('localhost', 5002);
  firestoreSettings.host = 'localhost:5002';
  firestoreSettings.ssl = false;
  db.settings(firestoreSettings);
  storage.useEmulator('localhost', 9199);
}

export { firebase, db, auth, perf, storage };
