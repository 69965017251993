









































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/auth';
import { User } from '@/models/user';
import ResetPasswordPrompt from '@/components/ResetPasswordPrompt.vue';

@Component({
  components: { ResetPasswordPrompt },
})
export default class AppBar extends Vue {
  get user(): User | null {
    return this.$store.state.auth.user;
  }
  get drawer(): boolean {
    return this.$store.state.auth.drawer;
  }

  async signOut() {
    await firebase.auth().signOut();

    if (this.$route.name !== 'Home') {
      this.$router.replace({
        name: 'Home',
      });
    }
  }
}
