import { Module } from 'vuex';
import { RootState } from '@/store';
import Race from '@/models/race';
import RaceService from '@/api/races';
import { RaceResult } from '@/models/raceResult';
import { RaceUploadDto } from '@/models/raceUploadDto';
import { buildLeaderboard } from '@/firebase/functions';
import { BuildLeaderboardDto } from '@/models/buildLeaderboardDto';
import { UploadHistory } from '@/models';
import { Duration } from 'luxon';

export type RacesState = {
  raceList: Race[] | null;
  raceResults: Map<string, RaceResult[]>;
  allRaceList: Map<string, Race[]> | null;
};

export const races: Module<RacesState, RootState> = {
  namespaced: true,
  state: {
    raceList: null,
    raceResults: new Map(),
    allRaceList: new Map(),
  },
  getters: {
    getRaceListBasedOnSeriesId(
      state: RacesState
    ): (seriesId: string, orgId: string) => Race[] | null {
      return (seriesId: string, orgId: string) => {
        if (state.allRaceList) {
          if (state.allRaceList.has(`${orgId}-${seriesId}`)) {
            const raceList = state.allRaceList.get(`${orgId}-${seriesId}`);
            if (raceList) {
              return raceList;
            }
          }
        }
        return [];
      };
    },
    raceResults(state: RacesState): (raceId: string) => RaceResult[] | null {
      return (raceId: string) => {
        const raceResults = state.raceResults?.get(raceId);
        if (raceResults === undefined) {
          return null;
        }
        return raceResults.sort((a, b) => {
          if (state.raceList?.find(r => r.id === raceId)?.raceType.isStandardRace) {
            return a.time && b.time
              ? Duration.fromISOTime(a.time).as('seconds') -
                  Duration.fromISOTime(b.time).as('seconds')
              : NaN;
          } else {
            return a.meters && b.meters ? b.meters - a.meters : 0;
          }
        });
      };
    },
    raceUploadHistory(state: RacesState): (raceId: string) => UploadHistory | null {
      return (raceId: string) => {
        const raceUploadHistory = state.raceList?.find(race => race.id == raceId)?.uploadHistory;
        if (raceUploadHistory === undefined) {
          return null;
        }
        return raceUploadHistory;
      };
    },
    getRace(state: RacesState): (raceId: string) => Race | null {
      return (raceId: string) => {
        const race = state.raceList?.find(race => race.id == raceId);
        if (race === undefined) {
          return null;
        }
        return race;
      };
    },
  },
  actions: {
    async getAllRacesList({ commit, rootGetters }, payload: { orgId: string }) {
      const seriesIds = rootGetters['series/getAllSeriesIds'](payload.orgId);
      const allRaceList = await RaceService.getAllRacesList(payload.orgId, seriesIds);
      commit('SET_ALL_RACES_LIST', allRaceList);
    },
    async getRaceList({ commit }, payload: { orgId: string; seriesId: string }) {
      const raceList = await RaceService.getRaceList(payload.orgId, payload.seriesId);
      if (raceList) {
        commit('SET_RACE_LIST', raceList);
      } else {
        commit('SET_RACE_LIST', []);
      }
    },

    async getRaceResults({ commit }, payload: { orgId: string; seriesId: string; raceId: string }) {
      const raceResults = await RaceService.getRaceResults(
        payload.orgId,
        payload.seriesId,
        payload.raceId
      );

      if (raceResults) {
        commit('SET_RACE_RESULTS', { id: payload.raceId, results: raceResults });
      } else {
        commit('SET_RACE_RESULTS', { id: payload.raceId, results: [] });
      }
    },

    async addRace(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; race: Race }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.addRace(org.id, payload.seriesId, payload.race);
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error add race',
            message: 'failed to add race: ' + error,
          },
          { root: true }
        );
      }
    },

    async updateRace(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; race: Race }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.updateRace(org.id, payload.seriesId, payload.race);
          await buildLeaderboard(new BuildLeaderboardDto(org.id, payload.seriesId));
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update race',
            message: 'failed to update race: ' + error,
          },
          { root: true }
        );
      }
    },

    async deleteRace(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; raceId: string }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.deleteRace(org.id, payload.seriesId, payload.raceId);
          await buildLeaderboard(new BuildLeaderboardDto(org.id, payload.seriesId));
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error delete race',
            message: 'failed to delete race: ' + error,
          },
          { root: true }
        );
      }
    },

    async addRaceResult(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; raceId: string; raceResult: RaceResult }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.addRaceResult(
            org.id,
            payload.seriesId,
            payload.raceId,
            payload.raceResult
          );
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error add race result',
            message: 'failed to add race result: ' + error,
          },
          { root: true }
        );
      }
    },

    async updateRaceResult(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; raceId: string; raceResult: RaceResult }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.updateRaceResult(
            org.id,
            payload.seriesId,
            payload.raceId,
            payload.raceResult
          );
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update race result',
            message: 'failed to update race result: ' + error,
          },
          { root: true }
        );
      }
    },

    async deleteRaceResult(
      { dispatch, rootGetters },
      payload: { orgId: string; seriesId: string; raceId: string; raceResultId: string }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await RaceService.deleteRaceResult(
            org.id,
            payload.seriesId,
            payload.raceId,
            payload.raceResultId
          );
          await dispatch('getRaceList', { orgId: payload.orgId, seriesId: payload.seriesId });
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error delete race result',
            message: 'failed to delete race result: ' + error,
          },
          { root: true }
        );
      }
    },

    async uploadRaceResults({ dispatch, rootGetters }, raceUploadDto: RaceUploadDto) {
      try {
        const org = rootGetters['organizations/organizationDetails'](raceUploadDto.organizationId);
        if (org) {
          await RaceService.uploadRaceResults(
            raceUploadDto.organizationId,
            raceUploadDto.seriesId,
            raceUploadDto.raceId,
            raceUploadDto.raceResults
          );

          await dispatch('getRaceResults', {
            orgId: raceUploadDto.organizationId,
            seriesId: raceUploadDto.seriesId,
            raceId: raceUploadDto.raceId,
          });
          await buildLeaderboard(new BuildLeaderboardDto(org.id, raceUploadDto.seriesId));
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error upload race results',
            message: 'failed to upload race results: ' + error,
          },
          { root: true }
        );
      }
    },
    async updateUploadHistory(
      { rootGetters, dispatch },
      payload: {
        organizationId: string;
        seriesId: string;
        raceId: string;
        uploadHistory: UploadHistory;
      }
    ) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.organizationId);
        if (org) {
          await RaceService.updateRaceUploadHistory(
            payload.organizationId,
            payload.seriesId,
            payload.raceId,
            payload.uploadHistory
          );
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update upload history',
            message: 'failed to update upload history: ' + error,
          },
          { root: true }
        );
      }
    },
  },
  mutations: {
    SET_RACE_LIST(state, raceList: Race[] | null) {
      state.raceList = raceList;
    },
    SET_RACE_RESULTS(state, payload: { id: string; results: RaceResult[] }) {
      state.raceResults?.set(payload.id, payload.results);
    },
    SET_ALL_RACES_LIST(state, allRacesList: Map<string, Race[]> | null) {
      if (state.allRaceList) {
        const newRaceLists = new Map(state.allRaceList);
        allRacesList?.forEach((list, k) => newRaceLists.set(k, list));
        state.allRaceList = newRaceLists;
      } else {
        state.allRaceList = allRacesList;
      }
    },
  },
};
