


































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/auth';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
})
export default class Login extends Vue {
  error = null;
  email = '';
  password = '';
  showPassword = false;

  async submit() {
    try {
      this.$v.$touch();
      await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
      this.$router.replace({ name: 'Organizations' });
    } catch (err) {
      this.$store.dispatch('errorModal/showDialog', {
        title: 'Login Error',
        message: err.message,
      });
      this.error = err.message;
    }
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Must be valid e-mail');
    !this.$v.email.required && errors.push('E-mail is required');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.required && errors.push('Password is required');
    return errors;
  }
}
