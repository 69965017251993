import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Leaderboard from '@/views/Leaderboard.vue';

import { auth } from '@/firebase';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('@/views/Organizations.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:organizationSlug',
    name: 'Leaderboard',
    component: Leaderboard,
  },
  {
    path: '/organizations/:organizationSlug/series/:seriesSlug/eligibleParticipants',
    name: 'EpDetails',
    component: () => import('@/views/EligibleParticipants.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations/:organizationSlug/series/:seriesSlug/races/:raceSlug',
    name: 'RaceDetails',
    component: () => import('@/views/RaceResults.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
