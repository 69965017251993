import { db, auth } from '@/firebase';
import { epUpload } from '@/firebase/functions';
import { EligibleParticipant, EpUploadDto, Series, UploadHistory } from '@/models';
import { Converter } from '@/utils/converter';

export default {
  async updateEpUploadHistory(
    orgId: string,
    seriesId: string,
    uploadHistory: UploadHistory
  ): Promise<void> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }
    try {
      await db
        .collection('organizations')
        .doc(orgId)
        .collection('series')
        .doc(seriesId)
        .withConverter(new Converter<Series>())
        .set({ uploadHistory }, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },

  async uploadEligibleParticipants(orgId: string, seriesId: string, eps: EligibleParticipant[]) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    try {
      await epUpload(new EpUploadDto(orgId, seriesId, eps));
    } catch (error) {
      console.log(`error while uploading EPs: ${error.code} ${error.message} ${error.details}`);
    }
  },

  /**
   * Gets list of EPs from Firestore for a given organization and series
   * @param orgId The document id of the organization the eps are a part of
   * @param seriesId The document id of the series the eps are a part of
   * @returns a list of eps or null if none are found
   */
  async getEligibleParticipants(
    orgId: string,
    seriesId: string
  ): Promise<EligibleParticipant[] | null> {
    const user = auth.currentUser;
    if (user == null) {
      return null;
    }

    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('eligibleParticipants')
      .withConverter(new Converter<EligibleParticipant>())
      .get();

    const eps = querySnapshot.docs.map(doc => doc.data());

    return eps;
  },

  async addEligibleParticipant(
    orgId: string,
    seriesId: string,
    eligibleParticipant: EligibleParticipant
  ): Promise<void> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('eligibleParticipants')
      .withConverter(new Converter<EligibleParticipant>())
      .add(eligibleParticipant);
  },

  async updateEligibleParticipant(
    orgId: string,
    seriesId: string,
    eligibleParticipant: EligibleParticipant
  ): Promise<void> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('eligibleParticipants')
      .doc(eligibleParticipant.id)
      .withConverter(new Converter<EligibleParticipant>())
      .set(eligibleParticipant, { merge: true });
  },

  async deleteEligibleParticipant(
    orgId: string,
    seriesId: string,
    eligibleParticipant: EligibleParticipant
  ): Promise<void> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('eligibleParticipants')
      .withConverter(new Converter<EligibleParticipant>())
      .doc(eligibleParticipant.id)
      .delete();
  },
};
