import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth';
import { series } from '@/store/series';
import { organizations } from '@/store/organizations';
import { eligibleParticipants } from '@/store/eligibleParticipants';
import { races } from '@/store/races';
import { raceTypes } from '@/store/raceTypes';
import { leaderboards } from './leaderboards';
import { errorModal } from './errorModal';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export type RootState = {};

export default new Vuex.Store({
  modules: {
    auth,
    organizations,
    series,
    eligibleParticipants,
    races,
    raceTypes,
    leaderboards,
    errorModal,
  },
  strict: debug,
});
