import { firebase } from '@/firebase';

export class Converter<T extends { id?: string }>
  implements firebase.firestore.FirestoreDataConverter<T> {
  toFirestore(modelObject: T): firebase.firestore.DocumentData;
  toFirestore(
    modelObject: Partial<T>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  toFirestore(modelObject: any, options?: any) {
    const { id, ...data } = modelObject;
    return data;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions
  ): T {
    const data = snapshot.data(options) as T;
    data.id = snapshot.id;
    return data;
  }
}
