import { RaceResult } from './raceResult';

/**
 * RaceUploadDto is the upload object for a list of Race Results and their associated Organization, Series and Race
 */
export class RaceUploadDto {
  constructor(
    public organizationId: string,
    public seriesId: string,
    public raceId: string,
    public raceResults: RaceResult[]
  ) {}
}
