import { RaceType } from '@/models/raceType';
import { db, auth } from '@/firebase';
import { Converter } from '@/utils/converter';

export default {
  async getRaceTypes(): Promise<RaceType[] | null> {
    const user = auth.currentUser;
    if (user == null) {
      return null;
    }

    try {
      const querySnapshot = await db
        .collection('raceTypes')
        .withConverter(new Converter<RaceType>())
        .get();

      const raceTypes = querySnapshot.docs.map(doc => doc.data());
      raceTypes.sort((a, b) => {
        if (a.isStandardRace && b.isStandardRace) {
          return a.distanceInMeters - b.distanceInMeters;
        } else {
          return a.isStandardRace ? -1 : a.distanceInMeters;
        }
      });
      return raceTypes;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
