import { RaceType } from '@/models/raceType';
import { Module } from 'vuex';
import { RootState } from '@/store';
import RaceTypesService from '@/api/raceTypes';

export type RaceTypesState = {
  raceTypes: RaceType[] | null;
};

export const raceTypes: Module<RaceTypesState, RootState> = {
  namespaced: true,
  state: {
    raceTypes: null,
  },
  actions: {
    async getRaceTypes({ commit }) {
      const raceTypes = await RaceTypesService.getRaceTypes();

      commit('SET_RACETYPES', raceTypes);
    },
  },
  mutations: {
    SET_RACETYPES(state, raceTypes: RaceType[] | null) {
      state.raceTypes = raceTypes;
    },
  },
};
