import { Series } from '@/models/series';
import { db, auth } from '@/firebase';
import slugify from 'slugify';
import { Converter } from '@/utils/converter';
import { deleteSeries } from '@/firebase/functions/deleteRecursive';

export default {
  async getAllSeriesList(orgIds: string[]): Promise<Map<string, Series[]> | null> {
    const allSeriesList = new Map<string, Series[]>();

    for (let index = 0; index < orgIds.length; index++) {
      const querySnapshot = await db
        .collection('organizations')
        .doc(orgIds[index])
        .collection('series')
        .withConverter(new Converter<Series>())
        .get();

      const seriesList = querySnapshot.docs.map(doc => doc.data());
      allSeriesList.set(orgIds[index], seriesList);
    }

    return allSeriesList;
  },
  async getSeriesList(orgId: string): Promise<Series[] | null> {
    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .withConverter(new Converter<Series>())
      .get();

    const seriesList = querySnapshot.docs.map(doc => doc.data());

    return seriesList;
  },

  async addSeries(orgId: string, series: Series) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    const slug = slugify(series.name, { lower: true, strict: true });

    const seriesList = await this.getSeriesList(orgId);
    if (seriesList?.find(s => s.id == slug)) {
      throw new Error(`series ${slug} already exists`);
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(slug)
      .withConverter(new Converter<Series>())
      .set(series, { merge: true });
  },

  async updateSeries(orgId: string, series: Series) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(series.id)
      .withConverter(new Converter<Series>())
      .set(series, { merge: true });
  },

  async deleteSeries(organizationId: string, seriesId: string) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await deleteSeries({ organizationId, seriesId });
  },
};
