import { firebase } from '@/firebase';
import { DeleteRaceDto } from '@/models/deleteRaceDto';
import { DeleteSeriesDto } from '@/models/deleteSeriesDto';

export const deleteSeries: (
  data: DeleteSeriesDto
) => Promise<firebase.functions.HttpsCallableResult> = firebase
  .functions()
  .httpsCallable('deleteSeries');

export const deleteRace: (
  data: DeleteRaceDto
) => Promise<firebase.functions.HttpsCallableResult> = firebase
  .functions()
  .httpsCallable('deleteRace');
