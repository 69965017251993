






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { auth } from '@/firebase';

@Component
export default class ResetPasswordPrompt extends Vue {
  @Prop({ required: true }) readonly email!: string;

  dialog = false;

  async confirm() {
    await auth.sendPasswordResetEmail(this.email, {
      url: `${window.location.origin}/login`,
    });
    this.dialog = false;
  }
}
