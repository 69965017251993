import { EligibleParticipant } from './eligibleParticipant';

/**
 * EpUploadDto is the upload object for a list of Eligible Participants and their associated Organization and Series
 */
export class EpUploadDto {
  constructor(
    public organizationId: string,
    public seriesId: string,
    public eligibleParticipants: EligibleParticipant[]
  ) {}
}
