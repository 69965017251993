import { Module } from 'vuex';
import { RootState } from '@/store';
import { Error } from '@/models/error';

export type ErrorState = {
  dialog: boolean;
  error: Error;
};

export const errorModal: Module<ErrorState, RootState> = {
  namespaced: true,
  state: {
    dialog: false,
    error: { title: '', message: '' },
  },
  getters: {
    getDialogStatus(state: ErrorState) {
      return state.dialog;
    },
    getErrorMsg(state: ErrorState) {
      return state.error;
    },
  },
  actions: {
    showDialog({ commit }, error: Error) {
      if (!error.message) {
        error.message = 'Please Contact Swiftally';
      }
      commit('SHOW_DIALOG', error);
    },

    hideDialog({ commit }) {
      commit('HIDE_DIALOG');
    },
  },
  mutations: {
    SHOW_DIALOG(state, error) {
      state.dialog = true;
      state.error = error;
    },
    HIDE_DIALOG(state) {
      state.dialog = false;
      state.error = { title: '', message: '' };
    },
  },
};
