






















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ErrorModal extends Vue {
  get dialog() {
    return this.$store.state.errorModal.dialog;
  }

  get error() {
    return this.$store.state.errorModal.error;
  }

  closeModal() {
    this.$store.dispatch('errorModal/hideDialog');
  }
}
