import { Module } from 'vuex';
import { RootState } from '@/store';
import { Division } from '@/models/division';
import LeaderboardResult from '@/models/leaderboardResult';
import LeaderboardService from '@/api/leaderboard';

export type LeaderboardState = {
  leaderboards: LeaderboardResult[] | null;
  divisions: Division[] | null;
};

export const leaderboards: Module<LeaderboardState, RootState> = {
  namespaced: true,
  state: {
    leaderboards: null,
    divisions: null,
  },
  getters: {
    getLeaderboard(state: LeaderboardState) {
      return (divisionName: string) =>
        state.leaderboards
          ? state.leaderboards.filter(leaderboard => leaderboard.division == divisionName)
          : null;
    },
  },
  actions: {
    async getLeaderboards(
      { commit },
      payload: {
        orgId: string;
        seriesId: string;
      }
    ) {
      const leaderboards = await LeaderboardService.getLeaderboards(
        payload.orgId,
        payload.seriesId
      );

      commit('SET_LEADERBOARDS', leaderboards);
    },
    async getDivisions({ commit }, payload: { orgId: string }) {
      const divisions = await LeaderboardService.getDivisions(payload.orgId);

      commit('SET_DIVISIONS', divisions);
    },
  },
  mutations: {
    SET_LEADERBOARDS(state, leaderboards: LeaderboardResult[]) {
      state.leaderboards = leaderboards;
    },
    SET_DIVISIONS(state, divisions: Division[]) {
      state.divisions = divisions;
    },
  },
};
