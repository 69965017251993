













import { Component, Vue } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import Footer from '@/components/Footer.vue';
import ErrorModal from '@/components/ErrorModal.vue';

@Component({
  components: {
    AppBar,
    Footer,
    ErrorModal,
  },
})
export default class App extends Vue {}
