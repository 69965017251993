import { db } from '@/firebase';
import { Converter } from '@/utils/converter';
import { Division } from '@/models/division';
import LeaderboardResult from '@/models/leaderboardResult';
import { Organization } from '@/models';

export default {
  async getLeaderboards(orgId: string, seriesId: string): Promise<LeaderboardResult[] | null> {
    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('leaderboards')
      .withConverter(new Converter<LeaderboardResult>())
      .get();

    const leaderboards = querySnapshot.docs.map(doc => {
      const leaderboard = doc.data();
      leaderboard.races.sort(
        (a, b) => new Date(a.raceDate).getTime() - new Date(b.raceDate).getTime()
      );
      return leaderboard;
    });

    return leaderboards;
  },

  async getDivisions(orgId: string): Promise<Division[] | null> {
    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .withConverter(new Converter<Organization>())
      .get();

    const org = querySnapshot.data();

    if (!org) {
      return null;
    }

    return org.divisions.sort((a, b) => a.order - b.order);
  },
};
