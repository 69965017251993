import { Module } from 'vuex';
import { RootState } from '@/store';
import { User } from '@/models/user';
import firebase from 'firebase/app';

export type AuthState = {
  user: User | null;
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    getUser(state: AuthState): () => User | null {
      return () => {
        if (state.user) {
          return state.user;
        }
        return null;
      };
    },
  },
  actions: {
    setUser({ commit }, user: firebase.User | null) {
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit('SET_USER', null);
        commit('series/SET_SERIES_LIST', null, { root: true });
        commit('organizations/SET_ORGANIZATIONS', null, { root: true });
      }
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
  },
};
