import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import '@/registerServiceWorker';
import Vuelidate from 'vuelidate';
import { auth } from '@/firebase';

Vue.use(Vuelidate);

Vue.config.productionTip = false;

let app: Vue;
auth.onAuthStateChanged(async user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }

  await store.dispatch('auth/setUser', user);
});
