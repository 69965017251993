import { Organization } from '@/models/organization';
import { Module } from 'vuex';
import { RootState } from '@/store';
import OrganizationsService from '@/api/organization';

export type OrganizationsState = {
  organizations: Organization[] | null;
};

export const organizations: Module<OrganizationsState, RootState> = {
  namespaced: true,
  state: {
    organizations: null,
  },
  getters: {
    organizationDetails(state: OrganizationsState): (id: string) => Organization | null {
      return (organizationId: string) => {
        const org = state.organizations?.find(org => {
          return org.id == organizationId;
        });

        if (!org) {
          return null;
        }
        return org;
      };
    },
    getOrgIdFromSlug(state: OrganizationsState): (slug: string) => string | null {
      return (slug: string) => {
        const org = state.organizations?.find(org => {
          return org.slug == slug;
        });

        if (!org) {
          return null;
        }
        return org.id;
      };
    },
    getAllOrgIds(state: OrganizationsState): string[] | null {
      return state.organizations ? state.organizations?.map(org => org.id) : null;
    },
  },
  actions: {
    async getOrganizations({ commit }) {
      const organizations = await OrganizationsService.getOrganizations();

      commit('SET_ORGANIZATIONS', organizations);
    },

    async getOrganizationsList({ commit }) {
      const organizations = await OrganizationsService.getOrganizationsList();

      commit('SET_ORGANIZATIONS', organizations);
    },

    async updateOrganization({ dispatch }, orgUpdate: Organization) {
      try {
        await OrganizationsService.updateOrganization(orgUpdate);

        dispatch('getOrganizations');
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error update organization details',
            message: 'failed to update organization details: ' + error,
          },
          { root: true }
        );
      }
    },
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations: Organization[] | null) {
      state.organizations = organizations;
    },
  },
};
