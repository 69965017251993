import Race from '@/models/race';
import { db, auth } from '@/firebase';
import { Converter } from '@/utils/converter';
import slugify from 'slugify';
import { RaceResult, UploadHistory } from '@/models';
import { RaceUploadDto } from '@/models/raceUploadDto';
import { raceUpload } from '@/firebase/functions/raceUpload';
import { deleteRace } from '@/firebase/functions/deleteRecursive';

export default {
  async getAllRacesList(orgId: string, seriesIds: string[]): Promise<Map<string, Race[]> | null> {
    const allRacesList = new Map<string, Race[]>();

    for (let index = 0; index < seriesIds.length; index++) {
      const querySnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('series')
        .doc(seriesIds[index])
        .collection('races')
        .withConverter(new Converter<Race>())
        .get();

      const raceList = querySnapshot.docs.map(doc => doc.data());
      allRacesList.set(`${orgId}-${seriesIds[index]}`, raceList);
    }

    return allRacesList;
  },
  async updateRaceUploadHistory(
    orgId: string,
    seriesId: string,
    raceId: string,
    uploadHistory: UploadHistory
  ): Promise<void> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    try {
      await db
        .collection('organizations')
        .doc(orgId)
        .collection('series')
        .doc(seriesId)
        .collection('races')
        .doc(raceId)
        .withConverter(new Converter<Race>())
        .set({ uploadHistory }, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },

  async uploadRaceResults(
    orgId: string,
    seriesId: string,
    raceId: string,
    raceResults: RaceResult[]
  ) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    try {
      await raceUpload(new RaceUploadDto(orgId, seriesId, raceId, raceResults));
    } catch (error) {
      console.log(`error while uploading EPs: ${error.code} ${error.message} ${error.details}`);
    }
  },

  async getRaceList(orgId: string, seriesId: string): Promise<Race[] | null> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .withConverter(new Converter<Race>())
      .get();

    const raceList = querySnapshot.docs.map(doc => doc.data());

    return raceList;
  },

  async getRaceResults(
    orgId: string,
    seriesId: string,
    raceId: string
  ): Promise<RaceResult[] | null> {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    const querySnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(raceId)
      .collection('results')
      .withConverter(new Converter<RaceResult>())
      .get();

    const raceResults = querySnapshot.docs.map(doc => doc.data());

    return raceResults;
  },

  async addRace(orgId: string, seriesId: string, race: Race) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    const slug = slugify(race.name, { lower: true, strict: true });

    const raceList = await this.getRaceList(orgId, seriesId);
    if (raceList?.find(r => r.id == slug)) {
      throw new Error(`race ${slug} already exists`);
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(slug)
      .withConverter(new Converter<Race>())
      .set(race, { merge: true });
  },

  async updateRace(orgId: string, seriesId: string, race: Race) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(race.id)
      .withConverter(new Converter<Race>())
      .set(race, { merge: true });
  },

  async deleteRace(organizationId: string, seriesId: string, raceId: string) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await deleteRace({ organizationId, seriesId, raceId });
  },

  async addRaceResult(orgId: string, seriesId: string, raceId: string, raceResult: RaceResult) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(raceId)
      .collection('results')
      .withConverter(new Converter<RaceResult>())
      .add(raceResult);
  },

  async updateRaceResult(orgId: string, seriesId: string, raceId: string, raceResult: RaceResult) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(raceId)
      .collection('results')
      .doc(raceResult.id)
      .withConverter(new Converter<RaceResult>())
      .set(raceResult);
  },

  async deleteRaceResult(orgId: string, seriesId: string, raceId: string, raceResultId: string) {
    const user = auth.currentUser;
    if (user == null) {
      throw new Error('Not Authenticated');
    }

    await db
      .collection('organizations')
      .doc(orgId)
      .collection('series')
      .doc(seriesId)
      .collection('races')
      .doc(raceId)
      .collection('results')
      .doc(raceResultId)
      .delete();
  },
};
