import { Module } from 'vuex';
import { RootState } from '@/store';
import { Series, UploadHistory } from '@/models';
import SeriesService from '@/api/series';

export type SeriesState = {
  seriesList: Series[] | null;
  allSeriesList: Map<string, Series[]> | null;
};

export const series: Module<SeriesState, RootState> = {
  namespaced: true,
  state: {
    seriesList: null,
    allSeriesList: new Map(),
  },
  getters: {
    getSeriesBasedOnOrgId(state: SeriesState): (id: string) => Series[] | null {
      return (orgId: string) => {
        if (state.allSeriesList) {
          if (state.allSeriesList.has(orgId)) {
            const series = state.allSeriesList.get(orgId);
            if (series) {
              return series;
            }
          }
        }
        return null;
      };
    },
    seriesDetails(state: SeriesState): (id: string) => Series | null {
      return (seriesId: string) => {
        const series = state.seriesList?.find(series => series.id === seriesId);
        if (series === undefined) {
          return null;
        }
        return series;
      };
    },
    seriesEpUploadHistory(state: SeriesState): (id: string) => UploadHistory | null {
      return (seriesId: string) => {
        const seriesUploadHistory = state.seriesList?.find(series => series.id === seriesId)
          ?.uploadHistory;
        if (seriesUploadHistory === undefined) {
          return null;
        }
        return seriesUploadHistory;
      };
    },
    getAllSeriesIds(state: SeriesState): (id: string) => string[] | null {
      return (orgId: string) => {
        if (state.allSeriesList) {
          if (state.allSeriesList.has(orgId)) {
            const seriesIds = state.allSeriesList.get(orgId)?.map(series => series.id);
            if (seriesIds) {
              return seriesIds;
            }
          }
        }
        return null;
      };
    },
  },
  actions: {
    async getAllSeriesList({ commit, rootGetters }) {
      const orgIds = rootGetters['organizations/getAllOrgIds'];
      const allSeriesList = await SeriesService.getAllSeriesList(orgIds);
      commit('SET_ALL_SERIES_LIST', allSeriesList);
    },
    async getSeriesList({ commit, rootGetters }, orgId: string) {
      const org = rootGetters['organizations/organizationDetails'](orgId);
      if (org) {
        const seriesList = await SeriesService.getSeriesList(org.id);
        commit('SET_SERIES_LIST', seriesList);
      } else {
        commit('SET_SERIES_LIST', []);
      }
    },

    async addSeries({ dispatch, rootGetters }, payload: { orgId: string; series: Series }) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await SeriesService.addSeries(org.id, payload.series);
          await dispatch('getSeriesList', payload.orgId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error adding series',
            message: 'failed to add series: ' + error,
          },
          { root: true }
        );
      }
    },

    async updateSeries({ dispatch, rootGetters }, payload: { orgId: string; series: Series }) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await SeriesService.updateSeries(org.id, payload.series);
          await dispatch('getSeriesList', payload.orgId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error updating series',
            message: 'failed to update series: ' + error,
          },
          { root: true }
        );
      }
    },

    async deleteSeries({ dispatch, rootGetters }, payload: { orgId: string; seriesId: string }) {
      try {
        const org = rootGetters['organizations/organizationDetails'](payload.orgId);
        if (org) {
          await SeriesService.deleteSeries(org.id, payload.seriesId);
          await dispatch('getSeriesList', payload.orgId);
        } else {
          throw new Error('Unauthorized access to an organization');
        }
      } catch (error) {
        dispatch(
          'errorModal/showDialog',
          {
            title: 'Error delete series',
            message: 'failed to delete series: ' + error,
          },
          { root: true }
        );
      }
    },
  },
  mutations: {
    SET_SERIES_LIST(state, seriesList: Series[] | null) {
      state.seriesList = seriesList;
    },
    SET_ALL_SERIES_LIST(state, allSeriesList: Map<string, Series[]> | null) {
      state.allSeriesList = allSeriesList;
    },
  },
};
